<template>
    <CompModal class="query-criteria-modal" ref="comp_modal" title="设置查询条件" @on-close="onCloseModal">
        <div class="selected-box">
            <CheckboxGroup v-model="selected">
                <div class="item-box" v-for="(item, idx) in structure" :key="idx">
                    <p class="name">{{ idx + 1 }}. {{ item.fieldName }}</p>
                    <div class="check-box">
                        <Checkbox :label="item.fieldId">查询条件</Checkbox>
                    </div>
                </div>
            </CheckboxGroup>
            <div class="empty" v-if="!structure || structure.length <= 0">暂无数据</div>
        </div>
        <template #operate="evt">
            <div class="operate-btns">
                <div class="operate-btn highlight" @click="onSubmitSearchField">确定</div>
                <div class="operate-btn" @click="evt.close">取消</div>
            </div>
        </template>
    </CompModal>
</template>

<script>
import proxy from "@/api/proxy"
import CompModal from "../components/CompModal.vue"

export default {
    components: { CompModal },

    data() {
        return {
            structure: null,
            selected: [],
        }
    },

    props: {
        type: String,
    },

    methods: {
        display(appId) {
            this.appId = appId
            this.getTabChildsData()
            // 获取数据
            this.getSearchFieldData()
            // 显示弹窗
            this.$refs.comp_modal.display()
        },

        /**
         * 监听窗口关闭
         */
        onCloseModal() {
            this.selected = []
        },

        getTabChildsData() {
            this.$get("/gateway/api/fastDev/AppForm/findFormByAppId", {
                appId: this.appId,
            }).then(res => {
                if (res.code == "200") {
                    const fields = []
                    console.log(this.type === "DYTX")
                    for (let i = 0, fps = res.data.formProperties; i < fps.length; i++) {
                        for (let oi = 0, o = fps[i].formOperates; oi < o.length; oi++) {
                            let v = o[oi]

                            if (v.fieldType === "hidden" || this.getFormType(v) === "text") {
                                continue
                            }

                            fields.push(v)
                        }
                    }
                    this.structure = fields
                }
            })
        },

        /**
         * 获取表单项类型
         */
        getFormType(v) {
            if (v.validate?.inputValueType === "mobile") {
                return "mobile"
            }

            if (v.fieldType === "select" && v.childFormOperates?.length > 0) {
                return "radio"
            }

            if (v.fieldType === "uploadFile") {
                return "upload"
            }

            if (this.type !== "DYTX") {
                return { text: "input" }[v.fieldType] || v.fieldType
            }

            return v.fieldType
        },

        /**
         * 获取搜索字段数据
         */
        getSearchFieldData() {
            this.$get("/gateway/api/fastDev/AppForm/getSearchField", {
                appId: this.appId,
            }).then(res => {
                if (res.code == 200) {
                    const ids = []

                    for (let i = 0, l = res?.dataList; i < l?.length; i++) {
                        ids.push(l[i].fieldId)
                    }

                    this.selected = ids
                } else {
                    this.$Message.error(res.desc)
                }
            })
        },

        onSubmitSearchField() {
            const formdata = new FormData()

            formdata.append("appId", this.appId)

            for (let i = 0, l = this.selected; i < l.length; i++) {
                formdata.append("searchFieldList", l[i])
            }

            const xhr = new XMLHttpRequest()

            xhr.open("POST", `${proxy["/gateway"].target}/api/fastDev/AppForm/setSearchField`)

            xhr.setRequestHeader("Authorization", window.sessionStorage.getItem("accessToken"))

            xhr.onloadstart = () => {
                this.$Loading.start()
            }

            xhr.onloadend = () => {
                this.$Loading.finish()
            }

            xhr.onload = () => {
                if (xhr.status === 200) {
                    const res = JSON.parse(xhr.response)
                    if (res.code == 200) {
                        this.$Message.success("设置成功")
                        // 关闭弹窗
                        this.$refs.comp_modal.close()
                        // 触发事件
                        this.$emit("on-change")
                    } else this.$Message.error(res.desc)
                }
            }

            xhr.send(formdata)
        },
    },
}
</script>

<style lang="less">
.query-criteria-modal {
    .selected-box {
        width: 100%;

        .item-box {
            margin: 0 10px;
            padding: 15px 0 12px 8px;
            border-bottom: 1px solid #f3f3f3;
            display: flex;
            align-items: center;

            &:last-child {
                border-bottom: 0;
            }

            .name {
                flex-grow: 1;
            }

            .delete-box,
            .check-box {
                flex-shrink: 0;
            }

            .delete-box {
                margin-right: 8px;
            }

            .liefeng-icon {
                font-size: 16px;
                line-height: 1;
                font-weight: bold;
                cursor: pointer;
            }
        }

        .empty {
            position: absolute;
            top: 45px;
            left: 0;
            bottom: 40px;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #888;
        }
    }
}
</style>
