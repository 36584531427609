<template>
    <CompModal class="quota-modal" ref="comp_modal" title="报表指标设置">
        <div class="form-box">
            <div class="form-item">
                <div class="title">同时满足以下</div>
                <Select v-model="condition" style="width:300px" placeholder="请选择条件">
                    <Option value="AND">所有条件</Option>
                    <Option value="OR">任一条件</Option>
                </Select>
            </div>
            <div class="attr-box">
                <div class="name-box">
                    <div class="name">属性字段</div>
                    <div class="name">条件</div>
                    <div class="name value-box">值</div>
                    <div class="operate-add" @click="onAddItem">
                        <Icon class="icon" type="md-add" color="#fff" />
                    </div>
                </div>
                <div class="item-box" v-for="(item, idx) in list" :key="item.id">
                    <div class="item-form">
                        <div class="item">
                            <Select class="module" v-model="item.field" @on-change="onChangeField($event, idx)">
                                <Option v-for="(str, idx) in structure" :key="idx" :value="str.fieldId">{{ str.fieldName }}</Option>
                            </Select>
                        </div>
                        <div class="item">
                            <Select v-model="item.condition" class="module" placeholder="请选择条件" @on-change="onChangeCondition($event, idx)">
                                <Option v-for="opt in item.conditionOptions" :key="opt[0]" :value="opt[0]">{{ opt[1] }}</Option>
                            </Select>
                        </div>
                        <div class="item value-box" v-if="item.valueType !== 'null'">
                            <Select v-if="item.valueType === 'select'" :multiple="item.multiple" v-model="item.value" class="module" filterable>
                                <Option v-for="opt in item.valueOptions" :key="opt[0]" :value="opt[0]">{{ opt[1] }}</Option>
                            </Select>

                            <div v-else-if="item.condition === 'Between'" class="range-box">
                                <div class="range-item" v-if="item.valueType === 'datePicker'">
                                    <DatePicker v-model="item.minValue" class="range" type="date" placeholder="开始日期" transfer />
                                    <span>-</span>
                                    <DatePicker v-model="item.maxValue" class="range" type="date" placeholder="结束日期" transfer />
                                </div>
                                <div class="range-item" v-else>
                                    <Input v-model="item.minValue" class="range" type="text" placeholder="最小值" />
                                    <span>-</span>
                                    <Input v-model="item.maxValue" class="range" type="text" placeholder="最大值" />
                                </div>
                            </div>

                            <DatePicker v-else-if="item.valueType === 'datePicker'" v-model="item.value" class="module" type="date" placeholder="请选择日期" transfer />

                            <Input v-else v-model="item.value" class="module" type="text" placeholder="请输入值" />
                        </div>
                    </div>
                    <div class="operate">
                        <div class="operate-item remove" @click="onRemoveItem(idx)">
                            <Icon class="icon" type="md-remove" color="#fff" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <template #operate="evt">
            <div class="operate-btns">
                <div class="operate-btn highlight" @click="onSubmit">确定</div>
                <div class="operate-btn" @click="evt.close">取消</div>
            </div>
        </template>
    </CompModal>
</template>

<script>
import CompModal from "../components/CompModal.vue"

export default {
    components: { CompModal },

    data() {
        return {
            condition: null,
            list: [
                {
                    id: this.$core.randomString(),
                },
            ],
            structure: null,
        }
    },

    methods: {
        async display(appId) {
            if (appId !== this.appId) {
                this.appId = appId
                // 获取结构
                await this.getStructure()
            }
            await this.getConditionData()
            // 显示弹窗
            this.$refs.comp_modal.display()
        },

        getConditionData() {
            return this.$get("/gateway/api/fastDev/AppData/findSearchCondition", {
                appId: this.appId,
            }).then(res => {
                if (res.code == 200 && res.data) {
                    const list = []
                    let data = res.data

                    this.condition = data.xor

                    for (let i = 0, l = data.fieldPropertiesList; i < l.length; i++) {
                        let v = l[i]

                        let structure = this.getStructureItem(v.fieldId)
                        // 没找到对应字段，跳过
                        if (!structure) continue

                        var value = null

                        let item = {
                            id: this.$core.randomString(),
                            field: v.fieldId,
                            condition: v.searchMethod,
                            conditionOptions: this.getConditionOption(structure.fieldType),
                            selected: structure,
                            type: structure.fieldType,
                        }

                        if (v.searchMethod === "Between") {
                            if (item.type === "datePicker") {
                                item.minValue = v.minValue ? new Date(v.minValue) : null
                                item.maxValue = v.maxValue ? new Date(v.maxValue) : null
                            } else {
                                item.minValue = v.minValue
                                item.maxValue = v.maxValue
                            }
                        }

                        if (item.type === "checkbox" && v.fieldValue) {
                            value = v.fieldValue.split(",")
                            item.multiple = true
                        }

                        item.value = value || v.fieldValue

                        // 获取值的配置
                        this.getValueConfig(item)

                        list.push(item)
                    }

                    // 如果数组为空，添加一条默认空数据
                    if (list.length === 0) {
                        list.push({
                            id: this.$core.randomString(),
                        })
                    }

                    this.list = list
                }
            })
        },

        /**
         * 根据 fieldId 获取字段属性
         */
        getStructureItem(key) {
            let structure = this.structure

            if (typeof key === "number") {
                return structure?.[key]
            }

            for (let i = 0; i < structure.length; i++) {
                let v = structure[i]

                if (v.fieldId === key) {
                    return v
                }
            }
        },

        getStructure() {
            this.$Message.loading({
                content: "加载中...",
                duration: 0,
            })

            return this.$get("/gateway/api/fastDev/AppForm/findFormByAppId", {
                appId: this.appId,
            })
                .then(res => {
                    if (res.code == "200") {
                        const data = res.data
                        const structure = []

                        for (let i = 0, fps = data.formProperties; i < fps.length; i++) {
                            for (let oi = 0, o = fps[i].formOperates; oi < o.length; oi++) {
                                let v = o[oi]

                                if (v.fieldType === "hidden") {
                                    continue
                                }

                                structure.push(v)
                            }
                        }

                        this.structure = structure
                    }
                })
                .finally(this.$Message.destroy)
        },

        onAddItem() {
            this.list.push({
                id: this.$core.randomString(),
            })
        },

        onRemoveItem(idx) {
            this.list.splice(idx, 1)
            // 至少会显示一列空白
            if (this.list.length <= 0) {
                this.onAddItem()
            }
        },

        /**
         * 切换属性字段
         */
        onChangeField(evt, idx) {
            let item = this.list[idx]
            let structure = this.getStructureItem(evt)

            item.selected = structure
            item.type = structure.fieldType
            item.field = evt
            item.condition = null
            item.value = null
            item.conditionOptions = this.getConditionOption(item.type)
            // 获取值的类型配置
            this.getValueConfig(item)

            this.$set(this.list, idx, item)
        },

        /**
         *  监听切换条件事件
         */
        onChangeCondition(evt, idx) {
            let item = this.list[idx]
            // 获取值的类型配置
            this.getValueConfig(item)
            // 更新
            this.$set(this.list, idx, item)
        },

        /** 获取条件选项 */
        getConditionOption(type) {
            switch (type) {
                case "datePicker":
                case "inputNumber":
                case "number":
                    return [
                        ["Equal", "等于 ( = )"],
                        ["NotEqual", "不等于 ( != )"],
                        ["GreaterThan", "大于 ( > )"],
                        ["GreaterThanEqual", "大于等于 ( >= )"],
                        ["LessThan", "小于 ( < )"],
                        ["LessThanEqual", "小于等于 ( <= )"],
                        ["Between", "介于"],
                        ["IsNull", "等于空"],
                        ["IsNotNull", "不等于空"],
                    ]
                default:
                    return [
                        ["Equal", "等于 ( = )"],
                        ["NotEqual", "不等于 ( != )"],
                        ["IsNull", "等于空"],
                        ["IsNotNull", "不等于空"],
                    ]
            }
        },

        /** 获取值的类型和配置数据 */
        getValueConfig(item) {
            var type

            if (~["IsNull", "IsNotNull"].indexOf(item.condition)) {
                return (item.valueType = "null")
            }

            switch (item.type) {
                case "switch":
                    type = "select"
                    item.valueOptions = [
                        ["1", "是"],
                        ["2", "否"],
                    ]
                    break
                case "checkbox":
                case "select":
                case "radio":
                    var ops = []

                    for (let i = 0, l = item.selected.options; i < l.length; i++) {
                        let it = l[i]
                        ops.push([it.value, it.label])
                    }

                    item.valueOptions = ops
                    type = "select"

                    if (item.type === "checkbox") {
                        item.multiple = true
                    }

                    break
            }

            item.valueType = type || item.type
        },

        onSubmit() {
            if (!this.condition) {
                return this.$Message.warning("请选择条件")
            }

            const list = []

            for (let i = 0, l = this.list; i < l.length; i++) {
                let v = l[i]

                if (!v.selected || !v.condition) {
                    continue
                }

                let item = {
                    fieldId: v.selected.fieldId,
                    searchMethod: v.condition,
                }

                switch (v.condition) {
                    case "Between": // 介于
                        if (v.valueType === "datePicker") {
                            if (!v.minValue || !v.maxValue) return this.$Message.warning(`请选择${v.selected.fieldName}的${!v.minValue ? "开始" : "结束"}日期`)
                            if (v.minValue.getTime() > v.maxValue.getTime()) return this.$Message.warning(`${v.selected.fieldName}的开始日期不能大于结束日期`)
                            // 处理时间范围
                            item.minValue = this.$core.formatDate(v.minValue, "yyyy-MM-dd")
                            item.maxValue = this.$core.formatDate(v.maxValue, "yyyy-MM-dd")
                        } else {
                            if (!v.minValue || !v.maxValue) {
                                return this.$Message.warning(`请输入${v.selected.fieldName}的${!v.minValue ? "最小值" : "最大值"}`)
                            }
                            item.minValue = v.minValue
                            item.maxValue = v.maxValue
                        }
                        break
                    default:
                        if (!v.value) {
                            if (v.valueType === "null") {
                                break
                            }

                            return this.$Message.warning(`请输入${v.selected.fieldName}的条件值`)
                        }

                        item.fieldValue = v.value instanceof Array ? v.value.join(",") : v.value
                }

                list.push(item)
            }

            this.$post(
                "/gateway/api/fastDev/AppData/saveSearchCondition",
                {
                    appId: this.appId,
                    xor: this.condition,
                    fieldPropertiesList: list,
                },
                { "Content-Type": "application/json" }
            ).then(res => {
                if (res.code == 200) {
                    this.$Message.success("设置成功")
                    // 关闭当前弹窗
                    this.$refs.comp_modal.close()
                    // 触发事件
                    this.$emit("on-success")
                } else this.$Message.error(res.desc)
            })
        },
    },
}
</script>

<style lang="less">
.quota-modal {
    .form-box {
        .form-item {
            padding: 30px 20px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #e3e3e3;

            .title {
                margin-right: 10px;
            }
        }

        .attr-box {
            margin: 20px 10px;
            overflow-x: auto;

            .name-box {
                display: flex;
                align-items: flex-end;
                margin-bottom: 10px;

                .name {
                    padding: 0 10px;
                    width: 200px;
                    margin: 0 10px;
                }

                .value-box {
                    width: 300px;
                }

                .operate-add {
                    cursor: pointer;
                    margin: 0 10px;
                    padding: 0 15px;
                    font-size: 20px;
                    border-radius: 4px;
                    background: #6fc3f8;
                }
            }

            .item-box {
                margin-bottom: 10px;
                display: flex;

                .item-form {
                    width: 760px;
                    display: flex;
                    flex-shrink: 0;
                }

                .item {
                    margin: 0 10px;

                    .module {
                        width: 200px;
                    }

                    .range-box {
                        width: 200px;

                        .range-item {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            .range {
                                width: 90px;
                            }
                        }
                    }

                    &.value-box {
                        width: 300px;

                        .module {
                            width: 300px;
                        }

                        .range-box {
                            width: 300px;

                            .range-item {
                                width: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;

                                .range {
                                    width: 140px;
                                }
                            }
                        }
                    }
                }

                .operate {
                    margin: 0 10px;

                    .operate-item {
                        cursor: pointer;
                        padding: 0 15px;
                        font-size: 20px;
                        border-radius: 4px;

                        &.add {
                            background: #6fc3f8;
                        }

                        &.remove {
                            background: #f88662;
                        }
                    }
                }
            }
        }
    }
}
</style>
