<template>
    <div class="residentdatabase-data-table">
        <!-- 主体 -->
        <CompContent :title="title" @on-search="onSearch" @on-reset="onReset">
            <template #operate>
                <div class="button-box" v-if="!disvable">
                    <Button v-if="type === 'DYTX'" @click="$refs.search_setting.display(appId)">用户搜索设置</Button>
                    <Dropdown v-if="type === 'DYTX'" transfer @on-click="onDisplayQrCode">
                        <Button icon="md-qr-scanner">
                            生成二维码
                            <Icon type="ios-arrow-down"></Icon>
                        </Button>
                        <template #list>
                            <DropdownMenu>
                                <DropdownItem name="write">填报二维码</DropdownItem>
                                <DropdownItem name="search">查询二维码</DropdownItem>
                                <DropdownItem name="list">列表二维码</DropdownItem>
                            </DropdownMenu>
                        </template>
                    </Dropdown>
                    <Button type="info" v-if="!groupId" @click="$refs.communication_group.display(appId)">创建畅联组</Button>
                    <Dropdown v-else transfer @on-click="onClickGroup">
                        <Button>
                            沟通群：{{ groupName || "未设置群名" }}
                            <Icon type="ios-arrow-down"></Icon>
                        </Button>
                        <template #list>
                            <DropdownMenu>
                                <DropdownItem name="detail">查看详情</DropdownItem>
                                <DropdownItem name="delete">删除</DropdownItem>
                            </DropdownMenu>
                        </template>
                    </Dropdown>
                    <Button icon="md-refresh" type="info" @click="onRefreshActive">刷新</Button>
                    <Button icon="md-search" type="info" @click="$refs.query_criteria.display(appId)">设置查询条件</Button>
                    <!-- <Button icon="ios-chatbubbles-outline" type="info" @click="$refs.send_message.display(appId)">发消息</Button> -->
                    <Button icon="ios-build" type="info" v-if="source === 'report'" @click="onDisplayQuota">报表指标设置</Button>
                    <Button icon="md-add" type="info" v-if="source === 'collect'" @click="onDisplayImport">导入</Button>
                    <Button icon="ios-download-outline" v-if="source === 'report' || type === 'DYTX'" type="warning" @click="onExport">导出</Button>
                    <Button icon="md-trash" type="error" v-if="source !== 'report'" @click="onBatchRemove">批量删除</Button>
                    <Button icon="md-add" type="primary" v-if="source === 'collect'" @click="onDisplayForm(appId)">新增</Button>
                </div>
            </template>
            <template #search="evt">
                <div v-for="item in searchFields" :key="item.fieldId">
                    <Select
                        v-if="item.fieldType === 'radio' || item.fieldType === 'select' || item.fieldType === 'checkbox'"
                        v-model.trim="evt.search[item.fieldId]"
                        :placeholder="'请选择' + item.fieldName"
                        :multiple="item.fieldType === 'checkbox'"
                        class="module"
                        transfer
                    >
                        <Option v-for="opt in item.options" :key="opt.value" :value="opt.value">{{ opt.label }}</Option>
                    </Select>

                    <Select v-else-if="item.fieldType === 'switch'" v-model.trim="evt.search[item.fieldId]" :placeholder="'请选择' + item.fieldName" class="module" transfer>
                        <Option value="1">是</Option>
                        <Option value="2">否</Option>
                    </Select>

                    <DatePicker v-else-if="item.fieldType === 'datePicker'" v-model.trim="evt.search[item.fieldId]" class="module" type="date" placeholder="请选择日期" transfer />

                    <Input v-else v-model.trim="evt.search[item.fieldId]" :placeholder="'请输入' + item.fieldName"></Input>
                </div>
            </template>
            <LiefengTable
                class="liefeng-table"
                :talbeColumns="columns"
                :tableData="tableData"
                :fixTable="true"
                :curPage="pageForm.page"
                :total="pageForm.total"
                :pagesizeOpts="[20, 30, 50, 100]"
                :page-size="pageForm.size"
                :backgroundText="watermark"
                @hadlePageSize="onChangeTableSize"
                @tableSelect="onChangeSelection"
            ></LiefengTable>
        </CompContent>

        <!-- <CompTable :title="title" :talbeColumns="columns" :tableData="tableData"></CompTable> -->
        <!-- 数据表单 -->
        <DataForm v-if="showModal" ref="data_form" :title="title" :type="type" @on-change="changeModal" :code="orgCode"></DataForm>
        <!-- 导入 -->
        <ImportsUpload ref="imports_upload" :appId="appId" :type="type" @on-success="getTableData"></ImportsUpload>
        <!-- 详情 -->
        <DetailsFrom ref="details_from" :type="type" :code="orgCode" @on-refresh="getTableData"></DetailsFrom>
        <!-- 操作记录 -->
        <OperationRecord ref="operation_record"></OperationRecord>
        <!-- 指标设置 -->
        <Quota ref="quota" @on-success="getTableData"></Quota>
        <!-- 发消息 -->
        <SendMessage ref="send_message" :communityCode="orgCode"></SendMessage>
        <!-- 查询条件 -->
        <QueryCriteria ref="query_criteria" :type="type" @on-change="getSearchFieldData"></QueryCriteria>
        <!-- 二维码 -->
        <CompQrCode ref="comp_qrcode"></CompQrCode>
        <!-- 预览图片 -->
        <Preview ref="preview"></Preview>
        <!-- 用户搜索设置 -->
        <SearchSetting ref="search_setting"></SearchSetting>
        <!-- 详情 -->
        <Detail ref="detail" :appId="appId"></Detail>
        <!-- 沟通群表单 -->
        <CommunicationGroup ref="communication_group" :appId="appId" @on-success="onCreateGroupSuccess"></CommunicationGroup>
    </div>
</template>

<script>
import CompContent from "../../customform/components/CompContent.vue"
import LiefengTable from "@/components/LiefengTable.vue"
import DataForm from "./form.vue"
import ImportsUpload from "../imports/upload.vue"
import Quota from "../children/Quota.vue"
import SendMessage from "../children/SendMessage"
import CompQrCode from "../components/CompQrCode.vue"

import DetailsFrom from "../children/DetailsFroms.vue"
import OperationRecord from "../children/OperationRecord.vue"
import ElemLook from "../components/ElemLook.vue"
import Preview from "../../comment/components/Preview"
import QueryCriteria from "../children/QueryCriteria.vue"
import SearchSetting from "../children/SearchSetting.vue"
import Detail from "./detail.vue"
import CommunicationGroup from "../children/CommunicationGroup.vue"

import CanvasUtils from "../utils/canvas_utils"
import Route from "../../communitymobilization/entity/Route"
import proxy from "@/api/proxy"

import CompTable from "../../jointly/components/CompForm.vue"
import Axios from "axios"
import Utils from "../../jointly/utils/utils"

import qs from "qs"

export default {
    components: {
        CompTable,
        CompContent,
        LiefengTable,
        DataForm,
        ImportsUpload,
        OperationRecord,
        DetailsFrom,
        Quota,
        SendMessage,
        CompQrCode,
        CompContent,
        Preview,
        QueryCriteria,
        SearchSetting,
        Detail,
        CommunicationGroup,
    },

    data() {
        return {
            columns: [],
            tableData: [],

            pageForm: {
                page: 1,
                total: 0,
                size: 20,
            },

            title: this.$core.getUrlParam("title"),

            searchData: {},

            appId: this.$core.getUrlParam("id"),
            // 禁用
            disvable: this.$core.getUrlParam("d") === "true",
            // 源, 收集: collect, 报表: report
            source: "",

            orgCode: this.$core.getUrlParam("orgCode") || parent.vue.loginInfo.userinfo.orgCode,

            // 搜索字段列表
            searchFields: null,
            // 水印
            watermark: null,
            // 类型
            type: this.$core.getUrlParam("type"),
            // 沟通群名称
            groupName: this.$core.getUrlParam("gn") || "",
            // 沟通群ID
            groupId: this.$core.getUrlParam("gid") || "",

            postData: {},
            showModal: false,
        }
    },

    created() {
        this.source = this.$core.getUrlParam("s")

        this.getTableData()
        this.getSearchFieldData()
    },

    mounted() {
        const canvas = new CanvasUtils(10)
        const userinfo = parent.vue.loginInfo.userinfo
        // 逆时针旋转 30 度
        canvas.rotate(-30)
        // 绘制登录人信息 & 当前时间
        canvas.draw(
            `${userinfo.realName} ${userinfo.mobile.length >= 4 ? userinfo.mobile.substring(userinfo.mobile.length - 4, userinfo.mobile.length) : ""}`,
            this.$core.formatDate(new Date(), "MM-dd hh:mm")
        )
        // 获取 base64 图片
        this.watermark = canvas.toImage()
        // 销毁
        canvas.destroy()
    },

    methods: {
        changeModal(val) {
            console.log(val)

            this.showModal = false
            if (val == false) {
            } else {
                this.getTableData()
            }
        },
        /**
         * 刷新激活状态
         */
        onRefreshActive() {
            const l = this.$Message.loading("刷新中...")

            this.$post("/gateway/api/fastDev/AppData/activeUserByAppId", {
                appId: this.appId,
            })
                .then(res => {
                    if (res.code !== "200") {
                        return this.$Message.error((res.desc || res.msg || "系统繁忙，请稍后再试！").slice(0, 50))
                    }

                    this.$Message.success("刷新成功！")
                    this.getTableData()
                })
                .finally(l)
        },

        getTableData() {
            if (this.source === "collect") {
                this.getCollectTableData()
            } else {
                this.getReportTableData()
            }
            // 清空勾选
            this.selection = []
        },

        /**
         * 获取搜索字段数据
         */
        getSearchFieldData() {
            this.$get("/gateway/api/fastDev/AppForm/getSearchField", {
                appId: this.appId,
            }).then(res => {
                if (res.code == 200) {
                    this.searchFields = res.dataList
                } else {
                    this.$Message.error(res.desc.slice(0, 50))
                }
            })
        },

        /**
         * 获取报表表格数据
         */
        getReportTableData() {
            const load = this.$Message.loading("加载中...")

            var url

            if (this.type === "street") {
                url = `/syaa/api/syuser/pc/streetUser/selectUserBaseReport?appId=${this.appId}&page=${this.pageForm.page}&pageSize=${this.pageForm.size}&streetCode=${this.orgCode}`
            } else {
                url = `/syaa/api/syuser/pc/userBaseIndex/selectUserBaseReport?appId=${this.appId}&page=${this.pageForm.page}&pageSize=${this.pageForm.size}&communityCode=${this.orgCode}`
            }

            this.$post(url, this.getCondition(), {
                "Content-Type": "application/json",
            })
                .then(res => {
                    if (res.code == 200) {
                        const data = res.data
                        this.pageForm.total = data.maxCount
                        this.processColumns(data)
                    } else if (res.code === "REPORT_COLUMN_NOT_EXIST") {
                        this.$Modal.confirm({
                            title: "提示",
                            content: "报表指标未设置，是否立即前往设置？",
                            onOk: () => {
                                this.onDisplayQuota()
                            },
                        })
                    } else this.$Message.error(res.desc.slice(0, 50))
                })
                .finally(load)
        },

        getCollectTableData() {
            const load = this.$Message.loading("加载中...")
            this.$post(`/gateway/api/fastDev/AppData/findPageData?appId=${this.appId}&page=${this.pageForm.page}&pageSize=${this.pageForm.size}`, this.getCondition(), {
                "Content-Type": "application/json",
            })
                .then(res => {
                    if (res.code == 200) {
                        const data = res.data
                        this.postData = data
                        this.pageForm.total = data.maxCount
                        this.processColumns(data)
                    } else this.$Message.error(res.desc.slice(0, 50))
                })
                .finally(load)
        },

        /**
         * 处理列表数据
         */
        processColumns(data) {
            const columns = [
                {
                    type: "selection",
                    width: 60,
                    align: "center",
                    fixed: "left",
                },
                {
                    title: "序号",
                    type: "index",
                    width: 80,
                    align: "center",
                },
            ]

            const columnNameList = data.columnNameList
            const minWidth = {}

            for (let i = 0; i < columnNameList.length; i++) {
                const c = columnNameList[i]

                let width = this.getMinWidth(c)

                minWidth[`key-${i}`] = width

                columns.push({
                    title: c,
                    key: `key-${i}`,
                    minWidth: width,
                    align: "center",
                    render: (h, params) => {
                        const value = params.row[`key-${i}`]

                        // 判断值是否为图片链接
                        if (/^https:\/\//.test(value)) {
                            var images

                            // 是否为数组
                            if (/^\[.*\]$/.test(value)) {
                                images = JSON.parse(value)
                            } else if (/\|/.test(value)) {
                                images = value.split("|")
                            } else if (/,/.test(value)) {
                                images = value.split(",")
                            } else {
                                images = [value]
                            }

                            const files = []

                            images?.forEach(item => {
                                if (!item) return
                                files.push(
                                    h("div", {
                                        style: {
                                            width: "50px",
                                            height: "50px",
                                            margin: "3px",
                                            background: `url(${item})`,
                                            "background-size": "cover",
                                            "background-position": "center",
                                            "border-radius": "3px",
                                            cursor: "pointer",
                                        },
                                        on: {
                                            click: () => {
                                                this.$refs.preview.display(item, images)
                                            },
                                        },
                                    })
                                )
                            })

                            return h(
                                "div",
                                {
                                    style: {
                                        display: "flex",
                                        "justify-content": "center",
                                        "flex-wrap": "wrap",
                                        margin: "5px 0",
                                    },
                                },
                                files
                            )
                        }

                        return h("p", value)
                    },
                })
            }

            // 最后添加操作列
            columns.push(this.getOperate())

            const tableData = []
            const dataList = data.dataList

            for (let i = 0; i < dataList.length; i++) {
                const d = dataList[i]

                const obj = {
                    id: d.formDataId || d.id,
                }

                for (let ci = 0, cs = d.columnDataList; ci < cs.length; ci++) {
                    let text = cs[ci]

                    // 没有值，跳过
                    if (!text) {
                        obj[`key-${ci}`] = "-"
                        continue
                    }

                    // 去除数组中括号
                    if (/^\[.*\]$/.test(text)) {
                        text = text.replace(/[\[\]"]/g, "")
                    }

                    let width = this.getMinWidth(text)

                    if (minWidth[`key-${ci}`] < width) {
                        minWidth[`key-${ci}`] = width
                    }

                    obj[`key-${ci}`] = text
                }

                tableData.push({ ...obj, buttonList: d.buttonList })
            }

            for (let i = 0; i < columns.length; i++) {
                let v = columns[i]
                v.minWidth = minWidth[v.key]
            }

            this.columns = columns
            this.tableData = tableData
        },

        /**
         * 获取最小宽度值
         */
        getMinWidth(text) {
            // 普通字符 20 px，中文加多 15 px/字
            let size = text.length * 20 + (text.match(/[\u4E00-\u9FA5]/g)?.length || 0) * 15
            // 最低默认宽度为 80
            return size > 80 ? (size > 300 ? 300 : size) : 80
        },

        /**
         * 获取查询条件
         */
        getCondition() {
            const res = []
            const sd = this.searchData

            for (let i = 0, keys = Object.keys(sd); i < keys.length; i++) {
                let k = keys[i]
                let value = sd[k]

                // 判空
                if (!value) continue

                // 处理日期格式数据
                if (value instanceof Date) {
                    value = this.$core.formatDate(value, "yyyy-MM-dd")
                }

                res.push({
                    queryFieldId: k,
                    queryFieldValue: value,
                    searchMethod: "Contains",
                })
            }

            return res
        },

        /**
         * 处理操作事件
         */
        getOperate() {
            if (this.disvable) {
                return {}
            }

            return {
                title: "操作",
                align: "center",
                fixed: "right",
                width: 250,
                render: (h, params) => {
                    var hs = [
                        h(ElemLook, {
                            style: "margin:0 3px",
                            props: {
                                row: params.row,
                                id: params.row.id,
                                source: this.source,
                                appId: this.appId,
                            },
                            on: {
                                "on-change": res => {
                                    Object.assign(params.row, res.value)
                                },
                            },
                        }),
                        h(
                            "Button",
                            {
                                props: {
                                    type: "success",
                                    size: "small",
                                },
                                style: "margin:0 3px",
                                nativeOn: {
                                    click: () => {
                                        this.onDisplayEditForm(params.row.id)
                                    },
                                },
                            },
                            "修改"
                        ),
                    ]
                    hs.push(
                        h(
                            "Dropdown",
                            {
                                props: {
                                    transfer: true,
                                },
                                style: "margin:0 3px",
                            },
                            [
                                h(
                                    "Button",
                                    {
                                        props: {
                                            type: "info",
                                            size: "small",
                                            icon: "ios-arrow-down",
                                        },
                                    },
                                    "更多"
                                ),

                                h(
                                    "DropdownMenu",
                                    {
                                        slot: "list",
                                    },
                                    [
                                        params.row.buttonList && params.row.buttonList.length
                                            ? h(
                                                  "DropdownItem",
                                                  {
                                                      nativeOn: {
                                                          click: () => {
                                                              let itemJson = {}
                                                              if (params.row.buttonList && params.row.buttonList.length && params.row.buttonList[0].method == "serviceRecord()") {
                                                                  // 服务记录
                                                                  itemJson = params.row.buttonList[0].paramsValueMap.mobile

                                                                  this.$core.openLayerFrame({
                                                                      type: 2,
                                                                      title: params.row.buttonList && params.row.buttonList.length ? params.row.buttonList[0].buttonName : "服务记录",
                                                                      content: `/page#/OperationServices?appId=${this.postData.childAppId}&search=${itemJson.join(",")}`,
                                                                      area: ["100%", "100%"],
                                                                  })
                                                              } else {
                                                                  itemJson = qs.stringify(params.row.buttonList[0])
                                                                  console.log("itemJson", itemJson)

                                                                  // 其他类型
                                                                  this.$core.openLayerFrame({
                                                                      type: 2,
                                                                      title: params.row.buttonList && params.row.buttonList.length ? params.row.buttonList[0].buttonName : "服务记录",
                                                                      content: `/page#/OperationServices?appId=${this.postData.childAppId}&${itemJson}`,
                                                                      area: ["100%", "100%"],
                                                                  })
                                                              }
                                                          },
                                                      },
                                                      style: {
                                                          textAlign: "center",
                                                      },
                                                  },
                                                  params.row.buttonList && params.row.buttonList.length ? params.row.buttonList[0].buttonName : "服务记录"
                                              )
                                            : null,

                                        h(
                                            "DropdownItem",
                                            {
                                                nativeOn: {
                                                    click: () => {
                                                        this.$refs.comp_qrcode.display(
                                                            `${proxy["/custom"].target}/#/apps/suiyue/pages/residentdatabase/detail/detail?i=${this.$route.query.id}&fi=${params.row.id}&up=true&del=`
                                                        )
                                                    },
                                                },
                                                style: {
                                                    textAlign: "center",
                                                },
                                            },
                                            "二维码"
                                        ),
                                        this.source == "report"
                                            ? h(
                                                  "DropdownItem",
                                                  {
                                                      nativeOn: {
                                                          click: () => {
                                                              this.$refs.operation_record.display(params.row.id)
                                                          },
                                                      },
                                                      style: {
                                                          textAlign: "center",
                                                      },
                                                  },
                                                  "查看修改记录"
                                              )
                                            : null,
                                        h(
                                            "DropdownItem",
                                            {
                                                nativeOn: {
                                                    click: () => {
                                                        this.$refs.detail.export(params.row.id)
                                                    },
                                                },
                                                style: {
                                                    textAlign: "center",
                                                },
                                            },
                                            "导出"
                                        ),
                                        h(
                                            "DropdownItem",
                                            {
                                                nativeOn: {
                                                    click: () => {
                                                        this.onRemove([params.row.id])
                                                    },
                                                },
                                                style: {
                                                    textAlign: "center",
                                                },
                                            },
                                            "删除"
                                        ),
                                    ]
                                ),
                            ]
                        )
                    )

                    return h("div", hs)
                },
            }
        },

        onSearch(evt) {
            this.searchData = evt.value
            // 恢复第一页
            this.pageForm.page = 1
            // 加载数据
            this.getTableData()
        },

        /**
         * 重置搜索事件
         */
        onReset() {
            this.searchData = {}
            // 恢复第一页
            this.pageForm.page = 1
            // 加载数据
            this.getTableData()
        },

        /**
         * 分页事件改变
         */
        onChangeTableSize(val) {
            this.pageForm.page = val.page
            this.pageForm.size = val.pageSize
            // 加载数据
            this.getTableData()
        },

        /**
         * 显示修改窗口
         */
        async onDisplayEditForm(id) {
            if (this.source === "collect") {
                return this.$get(`/gateway/api/fastDev/AppData/findDataById/${id}`).then(res => {
                    if (res.code != 200) {
                        return this.$Message.error("获取详情失败")
                    }
                    this.onDisplayForm(id, res.data.dataMap)
                })
            }

            this.$get("/syaa/api/syuser/pc/uaUserInfo/selectUserByUbaseId", {
                ubaseId: id,
            })
                .then(res => {
                    if (res.code == 200) {
                        const data = res.data
                        const map = {}

                        data.formProperties.forEach(item => {
                            item.formOperates.forEach(v => {
                                map[v.fieldId] = v.fieldValue
                            })
                        })

                        this.onDisplayForm(id, map, true)
                    } else {
                        this.$Message.error({
                            content: res.desc.slice(0, 50),
                            background: true,
                        })
                    }
                })
                .catch(() => {
                    this.$Message.error({
                        content: "数据获取失败",
                        background: true,
                    })
                })
                .finally(() => {
                    this.$Message.destroy()
                })
        },

        /**
         * 显示报表指标设置页面
         */
        onDisplayQuota() {
            this.$refs.quota.display(this.appId)
        },

        /**
         * 显示二维码页面
         */
        onDisplayQrCode(evt) {
            this.$refs.comp_qrcode.display(
                `${proxy["/custom"].target}/#/apps/suiyue/pages/residentdatabase/${{ write: "form/form", search: "search/search", list: "table/table" }[evt]}?ti=${this.$core.getUrlParam("ti")}&c=${
                    this.orgCode
                }&type=DYTX`,
                this.title
            )
        },

        /**
         * 显示导入页面
         */
        onDisplayImport() {
            this.$refs.imports_upload.display()
        },

        /**
         * 显示表单页面
         */
        async onDisplayForm(id, value, isUbaseId = false) {
            this.showModal = true
            this.$nextTick(() => {
                // 显示表单页面
                this.$refs.data_form.display(this.appId, id, value, isUbaseId)
            })
        },

        /**
         * 导出功能
         */
        async onExport() {
            var url

            // 创建一个 a 标签用于下载
            const e = document.createElement("a")
            e.download = "数据导出_" + new Date().getTime()

            if (this.source === "report") {
                var res = await this.$get(`/syaa/api/syuser/pc/userBaseIndex/exportReportExcel?communityCode=${this.orgCode}&appId=${this.appId}`)

                if (res.code != 200) {
                    return this.$Message.error((res.desc || "请求出错").slice(0, 50))
                }

                url = res.data
            } else {
                var res = await Axios.get(`/gateway/api/fastDev/AppData/exportExcel/${this.appId}`, {
                    responseType: "blob",
                })
                let blob = new Blob([res.data], { type: "application/vnd.ms-excel" })
                url = URL.createObjectURL(blob)
            }

            e.href = url

            document.body.appendChild(e)
            e.click()
            document.body.removeChild(e)
        },

        onBack() {
            Route.back()
        },

        onRemove(ids) {
            this.$Modal.confirm({
                title: "删除确定",
                content: "您正在删除数据，是否继续？",
                onOk: async () => {
                    var load = this.$Message.loading("执行中...")

                    for (let i = 0; i < ids.length; i++) {
                        var item = ids[i]

                        try {
                            await (this.source === "collect"
                                ? this.$post(`/gateway/api/fastDev/AppData/deleteData/${item}`)
                                : this.$post("/syaa/api/syuser/pc/uaUserInfo/deleteUserById", {
                                      ubaseId: item,
                                  }))
                        } catch {
                            // 关闭加载框
                            load()
                            // 结束
                            return this.$Message.error("删除失败")
                        }
                    }
                    // 关闭加载框
                    load()
                    this.$Message.success("删除成功")
                    // 更新数据
                    this.getTableData()
                },
            })
        },

        onChangeSelection(v) {
            this.selection = v
        },

        /**
         * 批量删除
         */
        onBatchRemove() {
            const list = this.selection

            if (!list || list.length === 0) {
                return this.$Message.warning("请勾选需要批量删除的数据")
            }

            this.$Modal.confirm({
                title: "删除确定",
                content: "您正在删除数据，是否继续？",
                onOk: async () => {
                    this.$post("/gateway/api/fastDev/AppData/batchDeleteAppData?formDataId=" + list.map(v => v.id).join("&formDataId=")).then(res => {
                        if (res.code == 200) {
                            this.$Message.success("删除成功")
                            // 更新数据
                            this.getTableData()
                        }
                    })
                },
            })
        },

        /**
         * 创建畅联组
         */
        onCreateGroupSuccess(evt) {
            this.groupId = evt.value.groupId
            this.groupName = evt.value.groupName

            const h = Utils.paramsToJson(window.location.href)
            h.gid = this.groupId
            h.gn = this.groupName
            // 更新链接
            window.location.href = Utils.jsonToParams(h, window.location.href.split("?")[0])
        },

        /**
         * 点击沟通群菜单
         */
        onClickGroup(evt) {
            if (evt === "detail") {
                // 跳转到详情
                Route.jump("/groupindex")
            } else {
                // 删除沟通群
                this.$Modal.confirm({
                    title: "提示",
                    content: "确定删除该沟通群吗？",
                    onOk: () => {
                        this.$post(`/gateway/api/fastDev/UserGroupController/deleteChatGroup?appId=${this.appId}&groupId=${this.groupId}`).then(res => {
                            if (res.code != 200) {
                                return this.$Message.error(res.desc || res.msg || "请求失败")
                            }

                            this.$Message.success("删除成功")
                            this.groupName = null
                            this.groupId = null

                            const h = Utils.paramsToJson(window.location.href)
                            // 判断链接是否存在 gid
                            if (h.gid) {
                                h.gid = ""
                            }
                            if (h.gn) {
                                h.gn = ""
                            }
                            // 更新链接
                            window.location.href = Utils.jsonToParams(h, window.location.href.split("?")[0])
                        })
                    },
                })
            }
        },
    },
}
</script>
<style lang="less">
// .liefeng-table {
//     position: relative;
// }
</style>
