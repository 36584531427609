export default class CanvasUtils {
    events = {}
    padding
    canvas
    ctx

    constructor(padding = 0) {
        this.padding = padding
        // 创建 canvas 组件
        const canvas = (this.canvas = document.createElement("canvas"))
        // 添加到 body
        document.body.appendChild(this.canvas)
        // 设置 canvas 默认高度
        canvas.height = 30 + padding * 2
        // 监听高度变化
        Object.defineProperty(canvas, "$height", {
            get() {
                return this.height
            },
            set: v => {
                canvas.height = v
                this.__onResize()
            },
        })
        // 监听宽度变化
        Object.defineProperty(canvas, "$width", {
            get() {
                return this.width
            },
            set: v => {
                canvas.width = v
                this.__onResize()
            },
        })
        // 获取 canvas 内容对象
        this.ctx = canvas.getContext("2d")
    }

    /**
     * 绘制图片
     */
    draw(...texts) {
        const canvas = this.canvas,
            ctx = this.ctx
        // 根据文字长度设置 canvas 宽度
        canvas.$width = this.__getMaxWidth(texts) + this.padding * 2
        canvas.$height = texts.length * 30 + texts.length * 3 + this.padding * 2
        ctx.fillStyle = "#333333"
        ctx.textAlign = "center"
        ctx.font = "30px Microsoft YaHei"

        for (let i = 0; i < texts.length; i++) {
            ctx.fillText(texts[i], canvas.width / 2, 24 * (i + 1) + i * 10 + this.padding)
        }
    }

    /**
     * 转 base64 图片
     */
    toImage() {
        return this.canvas.toDataURL()
    }

    /**
     * 旋转
     * @param {number} rotation 旋转角度，如：顺时针 30 度 = 30
     */
    rotate(rotation) {
        // 缓存事件
        this.events["rotate"] = arguments
        // 根据旋转角度增加高度
        this.canvas.height = (Math.abs(rotation) / 45) * this.canvas.width
        // 平移
        this.ctx.translate(0, (Math.abs(rotation) / 45) * this.canvas.height)
        // 旋转
        this.ctx.rotate((Math.PI / (360 / rotation)) * 2)
    }

    /**
     * 销毁
     */
    destroy() {
        // 移除
        document.body.removeChild(this.canvas)
        // 置空
        this.canvas = this.ctx = null
    }

    /**
     * 获取最大宽度值
     */
    __getMaxWidth(texts) {
        var max = 0

        for (let i = 0; i < texts.length; i++) {
            let length = texts[i].length

            let size = length * 20 + (texts[i].match(/[\u4E00-\u9FA5]/g)?.length || 0) * 15

            if (max < size) {
                max = size
            }
        }

        return max
    }

    /**
     * 监听尺寸变化
     */
    __onResize() {
        for (let i = 0, ks = Object.keys(this.events); i < ks.length; i++) {
            this[ks[i]].call(this, ...this.events[ks[i]])
        }
    }
}
