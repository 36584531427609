<template>
    <CompModal ref="comp_modal" :class="{ isexport: isExport }" title="详情" width="500px">
        <div class="detail-box" ref="detail_box">
            <div class="item-box" v-for="(item, idx) in structure" :key="idx">
                <div class="title-item" v-if="item.fieldType === 'text'">
                    {{ item.fieldName }}
                </div>
                <div class="value-item" v-else-if="item.fieldType === 'upload'">
                    <div class="name">{{ item.fieldName }}:</div>
                    <div class="value">
                        <div class="photo" v-if="detail[item.fieldId]">
                            <div class="img-box" v-for="(p, i) in detail[item.fieldId] instanceof Array ? detail[item.fieldId] : [detail[item.fieldId]]" :style="{ 'background-image': 'url(' + p + ')' }"></div>
                        </div>
                        <span v-else>-</span>
                    </div>
                </div>
                <div class="value-item" v-else>
                    <div class="name">{{ item.fieldName }}:</div>
                    <div class="value">{{ (detail[item.fieldId] ? (detail[item.fieldId] instanceof Array ? detail[item.fieldId].join(",") : detail[item.fieldId]) : "-") || "-" }}</div>
                    <div class="unit" v-if="detail[item.fieldId] && item.describe">{{ item.describe }}</div>
                </div>
            </div>
        </div>
    </CompModal>
</template>

<script>
import CompModal from "../components/CompModal.vue"

import Request from "../../jointly/utils/request"
import FileUtil from "../../customform/utils/file"

export default {
    components: {
        CompModal,
    },

    data() {
        return {
            structure: null,
            detail: {},
            isExport: false,
        }
    },

    props: {
        appId: String,
    },

    methods: {
        display(id) {
            this.getDateil(id)
            this.$refs.comp_modal.display()
        },

        async export(id) {
            // return this.display(id)

            await this.getDateil(id)

            this.isExport = true

            this.$nextTick(() => {
                this.$refs.comp_modal.display()

                FileUtil.exportToPDF(this.$refs.detail_box).then(() => {
                    this.isExport = false
                    this.$refs.comp_modal.close()
                })
            })
        },

        async getDateil(id) {
            if (!this.structure) {
                await this.getStructure()
            }

            return Request.get(`/gateway/api/fastDev/AppData/findDataById/${id}`).then(res => {
                const d = res.dataMap

                Object.keys(d).forEach(k => {
                    if (typeof d[k] === "string" && /^\[.*\]$/.test(d[k])) {
                        d[k] = JSON.parse(d[k])
                    }
                })

                this.structure.forEach(v => {
                    if (v.fieldType === "radio" || v.fieldType === "checkbox") {
                        const data = d[v.fieldId]

                        if (data) {
                            d[v.fieldId] = this.findOptions(data, v.options)
                        }
                    }
                })

                this.detail = d
            })
        },

        /**
         * 获取表单结构
         */
        async getStructure() {
            return Request.get("/gateway/api/fastDev/AppForm/findFormByAppId", {
                appId: this.appId,
            }).then(res => {
                const forms = []

                for (let i = 0, fps = res.formProperties; i < fps.length; i++) {
                    for (let fi = 0, fos = fps[i].formOperates; fi < fos.length; fi++) {
                        const v = fos[fi]

                        // 忽略详细地址组件，合并在 ElemAddr 组件
                        if (v.fieldType === "hidden") {
                            continue
                        }

                        if (v.options) {
                            v.options.forEach(o => {
                                o.name = o.label
                            })
                        }

                        forms.push(v)
                    }
                }

                this.structure = forms
            })
        },

        findOptions(v, os) {
            if ((!v && v !== 0) || !os || os.length < 0) {
                return "-"
            }

            if (!(v instanceof Array)) {
                v = [v]
            }

            var res = []

            os.forEach(item => {
                if (v.includes(item.value)) {
                    let idx = v.indexOf(item.value)
                    v.splice(idx, 1)
                    res.push(item.label)
                }
            })

            if (v.length > 0) {
                res.push(...v)
            }

            return res.length > 0 ? res.join(",") : "-"
        },
    },
}
</script>

<style lang="less" scope>
.isexport {
    z-index: -1 !important;
    opacity: 0;
}

.detail-box {
    padding: 20px;

    .item-box {
        margin-bottom: 10px;

        .title-item {
            font-weight: bold;
            font-size: 16px;
            color: #333;
            margin-bottom: 10px;
        }

        .value-item {
            display: flex;
            font-size: 14px;

            .name {
                color: #888;
                flex-shrink: 0;
            }

            .unit {
                color: #666;
                flex-shrink: 0;
            }

            .value {
                margin: 0 10px;

                .photo {
                    position: relative;
                    display: flex;
                    flex-wrap: wrap;

                    .img-box {
                        width: 100px;
                        height: 100px;
                        background-size: cover;
                        background-repeat: no-repeat;
                        background-position: center center;
                        margin: 5px;
                        border-radius: 4px;
                        overflow: hidden;
                    }
                }
            }

            .checkbox-box {
                display: flex;
                flex-direction: column;
                margin-left: 15px;

                .taro-checkbox_checked {
                    margin-right: 5px;
                }
            }

            .radio-box {
                margin-left: 10px;
            }

            span {
                margin-left: 10px;
                line-height: 30px;
            }
        }
    }
}
</style>
