<template>
    <!-- 可见范围 -->
    <CompModal ref="comp_modal" title="用户搜索设置" :onSuccess="onSubmit" @on-close="onChangeVisible" height="auto">
        <div class="residentdatabase-search-setting">
            <ElemFormItem title="搜索字段设置">
                <Select placeholder="选择作为搜索条件的字段" multiple v-model="formdata.fieldId" transfer style="width:300px">
                    <Option v-for="item in structure" :value="item.field" :key="item.field">{{ item.title }}</Option>
                </Select>
            </ElemFormItem>
            <ElemFormItem title="权限设置">
                <div class="check-box">
                    <Checkbox v-model="formdata.read">查看</Checkbox>
                    <Checkbox v-model="formdata.update">修改</Checkbox>
                    <Checkbox v-model="formdata.add">新增</Checkbox>
                    <Checkbox v-model="formdata.delete">删除</Checkbox>
                </div>
            </ElemFormItem>
            <!-- <ElemFormItem title="自动匹配">
                <Checkbox>根据手机号自动匹配数据</Checkbox>
            </ElemFormItem> -->
        </div>
    </CompModal>
</template>

<script>
import proxy from "@/api/proxy"

import ElemFormItem from "../../jointly/components/elem-form-item"
import ElemCheck from "@/views/customform/components/ElemCheck.vue"
import Request from "../../jointly/utils/request"
import CompModal from "../components/CompModal"

export default {
    components: { ElemFormItem, ElemCheck, CompModal },

    data() {
        return {
            // 表单结构
            structure: null,
            // 表单数据
            formdata: {
                read: false,
                update: false,
                delete: false,
                add: false,
            },
        }
    },

    watch: {
        "formdata.read"(v) {
            if (!v) {
                let fd = this.formdata
                fd.update = false
                fd.delete = false
            }
        },
        "formdata.update"(v) {
            if (v) this.formdata.read = true
        },
        "formdata.delete"(v) {
            if (v) this.formdata.read = true
        },
    },

    methods: {
        async display(appId) {
            this.appId = appId
            // 获取数据
            this.getDetailData()
            // 表单结构
            if (!this.structure) {
                await this.getStructure()
            }
            // 显示弹窗
            this.$refs.comp_modal.display()
        },

        /**
         * 获取表单结构
         */
        async getStructure() {
            return Request.get("/gateway/api/fastDev/AppForm/findFormByAppId", {
                appId: this.appId,
            }).then(data => {
                const structure = []

                for (let i = 0, fps = data.formProperties; i < fps.length; i++) {
                    for (let fi = 0, fos = fps[i].formOperates; fi < fos.length; fi++) {
                        const v = fos[fi]

                        // 忽略
                        if (v.fieldType === "hidden" || v.fieldType === "text") {
                            continue
                        }

                        structure.push({
                            title: v.fieldName,
                            field: v.fieldId,
                        })
                    }
                }

                this.structure = structure
            })
        },

        getDetailData() {
            const fd = this.formdata

            this.$get("/gateway/api/fastDev/AppForm/getClientConfig", {
                appId: this.appId,
            }).then(res => {
                if (res.code == 200 && res.data) {
                    const cfg = res.data.clientConfig?.[0]

                    if (cfg) {
                        fd.fieldId = (()=>{
                            var list = []
                            cfg.searchFieldList.map(item =>{
                                list.push(item.fieldId)
                            })
                            return list
                        })() || cfg.searchFieldList?.[0].fieldId
                        Object.assign(fd, cfg.userAuth)
                    }
                }
            })
        },

        /**
         * 监听弹窗显示状态变化
         */
        onChangeVisible(evt) {
            // 关闭弹窗时，清空表单数据
            this.formdata = {}
        },

        /**
         * 设置可见范围
         */
        onSubmit() {
            const fd = this.formdata

            console.log(fd)

            if (!fd.fieldId) {
                return this.$Message.warning("请选择搜索字段")
            }

            Request.post("/gateway/api/fastDev/AppForm/setClientConfig", {
                appId: this.appId,
                ...fd,
                fieldId:fd.fieldId.join(','),
                isLike:true
            }).then(() => {
                this.$Message.success("提交成功")
                setTimeout(() => {
                    this.$refs.comp_modal.close()
                }, 1000)
            })
        },
    },
}
</script>

<style lang="less">
.residentdatabase-search-setting {
    padding: 10px 20px;

    .check-box {
        margin-top: 5px;
        display: flex;
        flex-direction: column;

        .ivu-checkbox-wrapper {
            margin-top: 6px;
        }
    }
}
</style>
