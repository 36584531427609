<template>
    <div>
        <CompModal class="residentdatabase-imports-model" ref="import_model" title="Excel导入" @on-close="onCloseImportModel">
            <div class="imports-step-box">
                <ElemStep :steps="importSteps" title="导入数据" :current="current">
                    <template v-slot:content="data">
                        <ElemUpload ref="elem_upload" v-if="data.item.type === 'UPLOAD'" type="file" @on-change="onChangeUpload"></ElemUpload>

                        <!-- <div class="verification-results" v-if="data.item.type === 'CHECK'"></div> -->
                    </template>
                </ElemStep>
            </div>
            <div class="operate-btns" :class="{ row: current === 2 }">
                <div class="operate-btn" :class="{ highlight: current === 1 }" v-show="current >= 1" @click="onSetMatch">设置对应关系</div>
                <div class="operate-btn highlight" v-show="current === 2" @click="onVerificationData">校验数据</div>
            </div>
        </CompModal>

        <CompModal class="residentdatabase-imports-model" ref="conversion_model" title="转换设置" width="750px">
            <template #default="elem">
                <div class="table-box">
                    <div class="base-box">
                        <div class="content-box">
                            <div class="head">
                                <div class="item" style="width:100px">序号</div>
                                <div class="item" style="width:350px">字段</div>
                                <div class="item" style="width:280px">对应匹配转换字段</div>
                            </div>
                            <div class="body">
                                <div class="row" v-for="(item, idx) in formStructure" :key="item.fieldId">
                                    <div class="column" style="width:100px">
                                        <div class="column-box">
                                            <p class="text-box">{{ idx + 1 }}</p>
                                        </div>
                                    </div>
                                    <div class="column" style="width:350px">
                                        <div class="column-box">
                                            <p class="text-box">{{ item.fieldName }}</p>
                                        </div>
                                    </div>
                                    <div class="column" style="width:280px">
                                        <div class="column-box">
                                            <Select v-model="item.bindFieldId" style="width:200px" clearable transfer>
                                                <Option v-for="item in fields" :value="item.id" :key="item.id">{{ item.title }}</Option>
                                            </Select>
                                        </div>
                                    </div>
                                </div>
                                <p class="empty" v-if="!formStructure || 0 >= formStructure.length">当前数据为空</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="operate-btns row">
                    <div class="operate-btn" @click="elem.close">取消</div>
                    <div class="operate-btn highlight" @click="onSubmitMatch">提交</div>
                </div>
            </template>
        </CompModal>

        <CompModal class="residentdatabase-imports-model" ref="check_model" title="校验数据" width="80%">
            <template #default="elem">
                <div class="table-box page">
                    <div class="base-box">
                        <div class="content-box">
                            <div class="head">
                                <div class="item" style="width:230px" v-for="(item, idx) in importFormStructure" :key="idx">{{ item }}</div>
                            </div>
                            <div class="body">
                                <div class="row" v-for="(item, idx) in importData" :key="idx">
                                    <div class="column" style="width:230px" v-for="(cd, idx) in item.columnDataList" :key="'column' + idx">
                                        <div class="column-box">
                                            <p class="text-box" :style="{ color: idx === 0 && cd && ~cd.indexOf('失败') ? 'red' : '' }">{{ cd || "-" }}</p>
                                        </div>
                                    </div>
                                </div>
                                <p class="empty" v-if="!importData || 0 >= importData.length">当前数据为空</p>
                            </div>
                        </div>
                        <div class="operating-box" :class="{ 'operating-suspension': true }">
                            <div class="operating-title">操作</div>

                            <div class="operating-item" v-for="(item, idx) in importData" :key="idx">
                                <div class="button-box" v-if="item.columnDataList[0] != '数据校验成功'">
                                    <!-- 修改 -->
                                    <a class="edit" @click="onDisplayEditForm(item)">编辑</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pagination-box">
                    <div class="statistics-box">
                        <p class="p">显示 {{ checkPageConf.pageSize || 0 }} 条记录/页，共 {{ checkPageConf.totalElements || 0 }} 条记录</p>
                    </div>
                    <div class="number-box">
                        <div class="info-box">当前第 {{ checkPageConf.page || 1 }} 页，共 {{ checkPageConf.totalPages || 1 }} 页</div>
                        <div class="pages-box" dark-class="page-box-dark">
                            <div class="previous-box" :class="{ available: checkPageConf.page > 1 }" @click="onChangePages('previous')">
                                <Icon class="icon" color="#fff" type="ios-arrow-back"></Icon>
                            </div>
                            <div class="current-box">
                                <div class="select-base" tabindex="0" hidefocus="true">
                                    <p class="number">{{ checkPageConf.page || 1 }}</p>
                                </div>
                            </div>
                            <div class="next-box" :class="{ available: checkPageConf.totalPages > checkPageConf.page }" @click="onChangePages('next')">
                                <Icon class="icon" color="#fff" type="ios-arrow-forward"></Icon>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="operate-btns row">
                    <div class="operate-btn" @click="elem.close">返回</div>
                    <div class="operate-btn highlight" @click="onSubmitCheck">确认</div>
                </div>
            </template>
        </CompModal>

        <DataForm ref="data_form" :forms="forms" :type="type" :isImport="true" @on-change="onChangeCheckData"></DataForm>
    </div>
</template>

<script>
import axios from "axios"

import CompModal from "../components/CompModal.vue"
import ElemStep from "../components/ElemStep.vue"
import ElemUpload from "@/views/customform/components/ElemUpload.vue"
import RequestPage from "../../jointly/utils/page"
import DataForm from "../data/form.vue"
import Utils from "../../customform/utils/utils"

export default {
    components: { CompModal, ElemStep, ElemUpload, DataForm },

    data() {
        return {
            current: 0,
            // 导入步骤
            importSteps: [
                {
                    title: "上传文件",
                    desc: "选择需要导入的Excel表",
                    type: "UPLOAD",
                },
                {
                    title: "设置对应关系",
                    desc: "设置导入的文件与配置的表单字段进行匹配",
                },
                {
                    title: "校验数据",
                    desc: "确认校验数据结果无误后，即可保存",
                    type: "CHECK",
                },
            ],
            // 字段数据
            fields: [],
            // 表单结构
            formStructure: [],
            // 已导入数据
            importData: null,
            // 导入表单结构
            importFormStructure: null,
            // 表单结构
            forms: null,
            // 页码配置
            checkPageConf: {},
        }
    },

    props: {
        appId: String,
        code: {
            type: String,
            default() {
                return this.$core.getUrlParam("orgCode")
            },
        },
        type: {
            type: String,
            required: false,
        },
    },

    watch: {
        current(val) {
            if (val === 0) {
                this.importFormStructure = null
            }

            if (val !== 2) {
                this.setCheckResultTxt("确认校验数据结果无误后，即可保存")
            }
        },

        appId() {
            this.initial = false
            this.current = 0
            this.$refs.elem_upload.onEmpty()
        },
    },

    methods: {
        display() {
            this.$refs.import_model.display()
        },

        /**
         * 获取导入 Excel 的标题
         */
        getExcelTitleField() {
            if (this.initial) return

            this.$get(`/gateway/api/fastDev/DataImp/getExcelField/${this.appId}`).then(res => {
                if (res.code == 200 && res.data) {
                    const data = res.data
                    const fields = new Array()
                    const keys = Object.keys(data)

                    for (let i = 0; i < keys.length; i++) {
                        let k = keys[i]

                        fields.push({
                            title: data[k],
                            id: k,
                        })
                    }

                    this.fields = fields
                } else this.$Message.error(res.code == 200 ? "解析 Excel 失败" : res.desc)
            })
        },

        /**
         * 获取自定义表单结构
         */
        getFormStructure() {
            if (this.initial) return

            this.$get("/gateway/api/fastDev/AppForm/findFormByAppId", {
                appId: this.appId,
            }).then(res => {
                if (res.code == "200") {
                    const data = res.data
                    const forms = []

                    for (let i = 0, fps = data.formProperties; i < fps.length; i++) {
                        for (let fi = 0, fos = fps[i].formOperates; fi < fos.length; fi++) {
                            const fo = fos[fi]

                            // 忽略
                            if (fo.fieldType === "hidden" || this.getFormType(fo) === "text") {
                                continue
                            }

                            forms.push(fo)
                        }
                    }

                    this.forms = forms

                    let formStructure = []

                    Utils.each(data.formProperties[0].formOperates, v => {
                        if (v.fieldType === "hidden" || this.getFormType(v) === "text") {
                            return
                        }

                        formStructure.push(v)
                    })

                    this.formStructure = formStructure
                }
            })
        },

        /**
         * 获取表单项类型
         */
        getFormType(v) {
            if (v.validate?.inputValueType === "mobile") {
                return "mobile"
            }

            if (v.fieldType === "select" && v.childFormOperates?.length > 0) {
                return "radio"
            }

            if (v.fieldType === "uploadFile") {
                return "upload"
            }

            if (this.type !== "DYTX") {
                return { text: "input" }[v.fieldType] || v.fieldType
            }

            return v.fieldType
        },

        /**
         * 监听上传变化事件
         */
        onChangeUpload(evt) {
            const value = evt.value

            if (!value || value.length <= 0) {
                return (this.current = 0)
            }

            this.$Message.loading({
                content: "文件上传中...",
                duration: 0,
            })

            const formdata = new FormData()

            formdata.append("file", value[0].file)

            // 上传 Excel 文件
            axios({
                method: "post",
                url: `/gateway/api/fastDev/DataImp/importExcel/${this.appId}?orgCode=` + this.code,
                data: formdata,
            })
                .then(res => {
                    if (res.data?.code == 200) {
                        this.current = 1
                        this.initial = false
                    } else
                        setTimeout(() => {
                            this.$Message.error(res.data.desc || "请求失败")
                        })
                })
                .catch(() => {
                    setTimeout(() => {
                        this.$Message.error("请求失败")
                    })
                })
                .finally(() => {
                    this.$Message.destroy()
                })
        },

        onSetMatch() {
            // 获取表结构
            this.getFormStructure()
            // 获取 Excel 标题字段值
            this.getExcelTitleField()
            // 已初始化
            this.initial = true
            // 显示窗口
            this.$refs.conversion_model.display()
        },

        /**
         * 提交匹配绑定对应字段
         */
        onSubmitMatch() {
            const fields = this.formStructure
            const arr = new Array()

            for (let i = 0; i < fields.length; i++) {
                const v = fields[i]

                if (v.required && !v.bindFieldId) {
                    return this.$Message.error(`${v.fieldName}需要绑定对应的字段`)
                }

                if (v.bindFieldId) {
                    arr.push({
                        excelFieldId: v.bindFieldId,
                        excelFieldName: this.getFieldNameById(v.bindFieldId),
                        formFieldId: v.fieldId,
                        formFieldName: v.fieldName,
                    })
                }
            }

            if (arr.length <= 0) {
                return this.$Message.error("需至少绑定一个字段")
            }

            this.$post(`/gateway/api/fastDev/DataImp/${this.type === "DYTX" ? `bindFieldByExcel2Form?appId=${this.appId}` : `bindField/${this.appId}`}`, arr, {
                "Content-Type": "application/json",
            })
                .then(res => {
                    if (res.code == 200) {
                        this.$Message.success("绑定成功")
                        // 关闭窗口
                        this.$refs.conversion_model.close()
                        // 获取校验结果
                        this.getCheckResult()
                        // 下一步
                        this.current = 2
                    } else {
                        this.$Message.error("绑定失败，信息：" + res.desc)
                    }
                })
                .catch(() => {
                    this.$Message.error("绑定失败")
                })
        },

        /**
         * 获取校验结果
         */
        getCheckResult() {
            this.$get(`/gateway/api/fastDev/DataImp/countStatusByAppId/${this.appId}`).then(res => {
                if (res.code == "200") {
                    const data = res.data
                    // 写入结果
                    this.setCheckResultTxt(`校验结果：总 ${data.dataAmount} 条， 成功 ${data.successAmount || 0} 条，<span style="color:red">失败 ${data.faildAmount || 0} 条</span>`)
                }
            })
        },

        /**
         * 写入校验结果文本
         */
        setCheckResultTxt(desc) {
            for (let i = 0, is = this.importSteps; i < is.length; i++) {
                const v = is[i]
                if (v.type === "CHECK") {
                    v.desc = desc
                    break
                }
            }
        },

        /**
         * 根据 ID 获取对应的标题
         */
        getFieldNameById(id) {
            const field = this.fields

            for (let i = 0; i < field.length; i++) {
                const v = field[i]

                if (v.id === id) {
                    return v.title
                }
            }

            return null
        },

        /**
         * 关闭导入窗口
         */
        onCloseImportModel() {
            // this.current = 0
            // this.$refs.elem_upload.onEmpty()
        },

        /**
         * 打开校验数据窗口
         */
        onVerificationData() {
            this.checkDataReqPage = new RequestPage("/gateway/api/fastDev/DataImp/findPageDataImp", {
                data: {
                    appId: this.appId,
                    validateStatus: true,
                },
                onLoadBefore: () => {
                    this.$Message.loading({
                        content: "数据正在加载中",
                        duration: 0,
                    })
                },
                onLoadAfter: () => {
                    this.$Message.destroy()
                },
                processData: res => {
                    const data = res.data

                    this.importFormStructure = this.moveForward(data.columnNameList)
                    this.columnIdList = data.columnIdList

                    this.checkPageConf = {
                        page: data.currentPage,
                        totalElements: data.maxCount,
                        pageSize: data.pageSize,
                        totalPages: data.maxPage,
                    }

                    return data
                },
                onChange: res => {
                    res?.forEach(v => {
                        v.columnDataList = this.moveForward(v.columnDataList)
                    })
                    this.importData = res
                },
            })

            this.$refs.check_model.display()
        },

        moveForward(list) {
            list.unshift(...list.splice(list.length - 2))
            return list
        },

        /**
         * 更新校验数据
         */
        onChangeCheckData() {
            this.checkDataReqPage.refresh()
            // 更新校验结果
            this.getCheckResult()
        },

        /**
         * 显示修改窗口
         */
        async onDisplayEditForm(val) {
            const map = {}
            const cdl = JSON.parse(JSON.stringify(val.columnDataList))
            // 裁剪掉前面两个元素
            cdl.splice(0, 2)

            for (let i = 0, fs = this.columnIdList; i < fs.length; i++) {
                map[fs[i]] = cdl[i]
            }

            this.onDisplayForm(val.id, map)
        },

        /**
         * 显示表单页面
         */
        async onDisplayForm(id, value) {
            if (!this.forms) {
                await this.getForms()
            }

            // 显示表单页面
            this.$refs.data_form.display(this.appId, id, value)
        },

        /**
         * 提前校验数据
         */
        onSubmitCheck() {
            this.$post(`/gateway/api/fastDev/DataImp/${this.type === "DYTX" ? `confirmData?appId=${this.appId}` : `confirmAndSyncUa/${this.appId}`}`)
                .then(res => {
                    if (res.code == 200) {
                        this.$Message.success("导入成功")
                        // 关闭全部窗口
                        this.$refs.conversion_model.closeAll()
                        // 清空数据
                        this.current = 0
                        // 清空上传文件
                        this.$refs.elem_upload.onEmpty()
                        this.$emit("on-success")
                    } else {
                        this.$Message.error(res.desc)
                    }
                })
                .catch(() => {
                    this.$Message.error("绑定失败")
                })
        },

        onChangePages(type) {
            if (type === "previous") {
                if (this.checkPageConf.page <= 1) {
                    return this.$Message.info("当前为第一页")
                }

                this.checkPageConf.page--
            } else {
                if (this.checkPageConf.page >= this.checkPageConf.totalPages) {
                    return this.$Message.info("当前为最后一页")
                }

                this.checkPageConf.page++
            }

            this.checkDataReqPage.setIndex(this.checkPageConf.page)
        },
    },
}
</script>

<style lang="less">
@import "../../customform/style/utils.less";

.residentdatabase-imports-model {
    .imports-step-box {
        padding: 30px;
    }

    .operate-btns {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        color: transparent;
        background: linear-gradient(transparent, rgba(255, 255, 255, 0.7));
        display: flex;
        justify-content: center;

        .operate-btn {
            cursor: pointer;
            margin: 10px 20px;
            width: ~"calc(100% - 40px)";
            max-width: 200px;
            background: #fff;
            height: 45px;
            color: #333;
            border-radius: 10px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #e3e3e3;

            &.highlight {
                background: #2faaf7;
                color: #fff;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            }
        }

        &.row {
            padding: 0 10px;

            .operate-btn {
                margin: 10px 5px 10px 5px;
                width: ~"calc(50% - 10px)";
            }
        }
    }

    .table-box {
        position: relative;
        width: 100%;
        height: ~"calc(100% - 75px)";
        margin-top: 10px;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        display: flex;
        flex-grow: 1;
        border-bottom: 1px solid #f3f3f3;

        &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
        }

        &.page {
            height: ~"calc(100% - 127px)";
        }

        .base-box {
            min-width: 100%;
            flex-shrink: 0;
            display: flex;
            align-items: flex-start;

            .content-box {
                min-height: 100%;
                min-width: 100%;
                padding: 0 5px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .head {
                    position: -webkit-sticky;
                    position: sticky;
                    top: 0;
                    min-width: 100%;
                    height: 60px;
                    background: #f3f3f3;
                    z-index: 10;
                    border-radius: 5px;
                    display: flex;

                    .item {
                        padding: 0 20px;
                        width: 200px;
                        height: 100%;
                        font-size: 15px;
                        color: #abb4b9;
                        font-weight: bold;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }

                .body {
                    min-width: 100%;
                    max-width: 100%;

                    .row {
                        position: relative;
                        height: 50px;
                        z-index: 1;
                        border: 1px solid transparent;
                        border-radius: 5px;
                        display: flex;
                        -webkit-transition: all 0.3s;
                        transition: all 0.3s;
                        -webkit-transition-timing-function: ease;
                        transition-timing-function: ease;
                        box-sizing: border-box;

                        &:hover {
                            z-index: 5;
                            border-color: #e3e3e3;
                            box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
                        }

                        &:nth-child(2n) {
                            background: #f9f9f9;
                        }

                        .column {
                            padding: 0 20px;
                            width: 200px;
                            height: 100%;

                            .column-box {
                                margin: 3px 0;
                                width: 100%;
                                height: ~"calc(100% - 3px)";
                                letter-spacing: 1px;
                                color: #000;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                overflow-x: auto;
                                overflow-y: hidden;
                                -webkit-overflow-scrolling: touch;

                                &::-webkit-scrollbar {
                                    height: 5px;
                                }

                                .text-box {
                                    font-size: 13px;
                                    max-width: 90%;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }

                                &:hover .text-box {
                                    overflow: initial;
                                    text-overflow: initial;
                                }

                                .normal-c {
                                    color: #07cd6f;
                                }

                                .normal-bg {
                                    background: #07cd6f;
                                }

                                .empty-c {
                                    color: #ffbb3a;
                                }

                                .empty-bg {
                                    background: #ffbb3a;
                                }

                                .subclass-box {
                                    cursor: pointer;
                                    display: flex;
                                    align-items: center;
                                    -webkit-transition: all 0.3s;
                                    transition: all 0.3s;
                                    -webkit-transition-timing-function: ease;
                                    transition-timing-function: ease;

                                    .point {
                                        width: 8px;
                                        height: 8px;
                                        border-radius: 100%;
                                    }

                                    .text {
                                        margin-left: 10px;
                                    }
                                }
                            }

                            .column-box-null {
                                color: #666;
                            }
                        }
                    }

                    .empty {
                        text-align: center;
                        font-size: 15px;
                        color: #888;
                        line-height: 25px;
                        position: absolute;
                        top: 60px;
                        right: 0;
                        left: 0;
                    }
                }
            }

            .operating-box {
                background: #fff;
                z-index: 10;
                min-height: 100%;
                padding-right: 5px;
                flex-grow: 1;
                flex-shrink: 0;
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .operating-title {
                    position: sticky;
                    top: 0;
                    min-width: 100%;
                    padding: 0 30px;
                    height: 60px;
                    font-size: 15px;
                    color: #abb4b9;
                    font-weight: bold;
                    background: #f3f3f3;
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                    border-radius: 5px;
                }

                .operating-item {
                    padding: 0 30px;
                    height: 50px;
                    letter-spacing: 1px;
                    color: #000;
                    min-width: 100%;
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;

                    &:nth-child(2n-1) {
                        background: #f9f9f9;
                    }

                    .button-box {
                        margin-right: 10px;

                        &:last-child {
                            margin-right: 0;
                        }

                        a,
                        button {
                            cursor: pointer;
                            padding: 5px 12px;
                            color: #fff;
                            font-size: 14px;
                            border: 0;
                            display: flex;
                            -webkit-transition: all 0.3s;
                            transition: all 0.3s;
                            -webkit-transition-timing-function: ease;
                            transition-timing-function: ease;
                            border-radius: 5px;

                            &:hover {
                                box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
                            }
                        }
                    }

                    .edit {
                        background: #00b3d9;
                    }

                    .delete {
                        background: #ff5722;
                    }

                    .entity {
                        background: #2faaf7;
                    }
                }
            }

            .operating-suspension {
                position: sticky;
                right: 0;
                box-shadow: -5px 0 5px rgba(0, 0, 0, 0.1);

                .operating-title {
                    border-radius: 0 5px 5px 0;
                }
            }
        }
    }

    .pagination-box {
        padding: 0 20px;
        height: 50px;
        border-bottom: 1px solid #f3f3f3;

        .flex;
        .flex-center-items;
        .flex-content(space-between);
        .flex-shrink;

        .statistics-box {
            .flex;
            .flex-center-items;

            .p {
                letter-spacing: 1px;
                color: #666;
                font-size: 14px;
            }

            .select-box {
                position: relative;
                margin: 0 10px;

                .select-base {
                    cursor: pointer;
                    padding: 5px 10px;
                    background: #fff;

                    .border;
                    .shadow(0 0 5px rgba(0, 0, 0, 0.1));
                    .radius(5px);
                    .flex;
                    .flex-center-all;

                    .icon {
                        margin-left: 10px;
                        width: 14px;
                        height: 14px;
                    }
                }

                .select-base-dark {
                    background: #1e1d2b;
                }

                .options-box {
                    visibility: hidden;
                    z-index: 40;
                    opacity: 0;

                    .transition(0.1s);
                    .absolute(initial, -10px, ~"calc(100% + 15px)", -10px);

                    &::after {
                        content: "";
                        border-left: 10px solid transparent;
                        border-right: 10px solid transparent;
                        border-top: 10px solid #2d3539;

                        .absolute(initial, initial, -10px, ~"calc(50% - 10px)");
                    }

                    .options-base {
                        width: 100%;
                        background: #2d3539;
                        max-height: 200px;

                        .scroll-y(5px);
                        .radius(5px);
                        .shadow(0 0 5px rgba(0, 0, 0, 0.1));

                        .item {
                            cursor: pointer;
                            width: 100%;
                            height: 40px;
                            text-align: center;
                            line-height: 40px;
                            color: #c3c3c3;

                            &:hover {
                                background: #4e5457;
                                color: #fff;
                            }
                        }
                    }
                }

                .options-activity {
                    visibility: initial;
                    opacity: 1;
                }
            }
        }

        .number-box {
            .flex;
            .flex-center-items;

            .info-box {
                margin-right: 30px;
                color: #666;
                font-size: 14px;
                letter-spacing: 1px;
            }

            .pages-box {
                height: 30px;
                border: 1px solid #e3e3e3;

                .flex;
                .shadow(0 0 5px rgba(0, 0, 0, 0.1));
                .radius(5px);

                .current-box {
                    position: relative;
                    height: 100%;

                    .select-base {
                        padding: 0 10px;
                        height: 100%;
                        background: #fff;

                        .flex;
                        .flex-center-all;

                        .icon {
                            margin-left: 10px;
                            width: 14px;
                            height: 14px;
                        }
                    }

                    .options-box {
                        visibility: hidden;
                        z-index: 40;
                        opacity: 0;

                        .transition(0.1s);
                        .absolute(initial, -10px, ~"calc(100% + 15px)", -10px);

                        &::after {
                            content: "";
                            border-left: 10px solid transparent;
                            border-right: 10px solid transparent;
                            border-top: 10px solid #2d3539;

                            .absolute(initial, initial, -10px, ~"calc(50% - 10px)");
                        }

                        .options-base {
                            width: 100%;
                            background: #2d3539;
                            max-height: 200px;

                            .scroll-y(5px);
                            .radius(5px);
                            .shadow(0 0 5px rgba(0, 0, 0, 0.1));

                            .item {
                                cursor: pointer;
                                width: 100%;
                                height: 40px;
                                text-align: center;
                                line-height: 40px;
                                color: #c3c3c3;

                                &:hover {
                                    background: #4e5457;
                                    color: #fff;
                                }
                            }
                        }
                    }

                    .options-activity {
                        visibility: initial;
                        opacity: 1;
                    }
                }

                .previous-box,
                .next-box {
                    cursor: no-drop;
                    width: 30px;
                    height: 100%;
                    background: #f3f3f3;

                    .flex;
                    .flex-center-all;

                    .icon {
                        width: 15px;
                        height: 15px;
                        -webkit-filter: contrast(0.5);
                        filter: contrast(0.5);
                    }
                }

                .previous-box {
                    .radius(5px 0 0 5px);
                }

                .next-box {
                    .radius(0 5px 5px 0);
                }
            }

            .page-box-dark {
                .current-box .select-base {
                    background: #1e1d2b;
                }

                .previous-box,
                .next-box {
                    background: #535353;
                }
            }

            .pages-box .available {
                cursor: pointer;
                background: #2faaf7;

                .icon {
                    -webkit-filter: initial;
                    filter: initial;
                }
            }
        }
    }
}
</style>
