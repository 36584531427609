<template>
    <div>
        <CompModal class="residentdatabase-send-message" ref="comp_modal" title="发消息" @on-close="onCloseModal">
            <template>
                <div class="form-box">
                    <div class="item-box">
                        <div class="title">
                            1.接收人
                            <span style="color:red">*</span>
                        </div>
                        <div class="select-box">
                            <ElemSelect
                                :multiple="true"
                                placeholder="请输入用户名或手机号码进行搜索"
                                :remotely="{ api: '/gateway/apps/basic/api/base/uc/getByMobileOrNickname', search: 'mobileOrNickname', id: 'globalId', value: 'nickName' }"
                                :value="formdata.members"
                                @on-change="formdata.members = $event.value"
                            ></ElemSelect>
                        </div>
                    </div>
                    <div class="item-box">
                        <div class="title">
                            2.内容
                            <span style="color:red">*</span>
                        </div>
                        <ElemTextarea title="发送内容" :value="formdata.text" @on-change="formdata.text = $event.value"></ElemTextarea>
                    </div>
                    <div class="item-box">
                        <div class="title">
                            3.图片
                        </div>
                        <ElemUpload ref="upload_image" :required="false" :value="formdata.image" :length="9" @on-change="formdata.image = $event.value"></ElemUpload>
                    </div>
                    <div class="item-box">
                        <div class="title">
                            4.文件
                        </div>
                        <ElemUpload ref="upload_file" :required="false" :value="formdata.file" type="file" @on-change="formdata.file = $event.value"></ElemUpload>
                    </div>
                </div>
            </template>
            <template #operate>
                <div class="operate-btns">
                    <div class="operate-btn highlight" @click="onSendImmediately">立即发送</div>
                    <!-- <div class="operate-btn" @click="onDisplaySetSendRegularly">设置定时发送</div> -->
                </div>
            </template>
        </CompModal>

        <Modal v-model="isDisplayTimeModal" title="定时发送配置" @on-ok="onSubmitSendRegularly">
            <div class="send-regularly-form-item">
                <div class="title">发送时间:</div>
                <DatePicker v-model="sendRegularly" type="datetime" placeholder="选择发送时间" style="width: 300px;" />
            </div>
        </Modal>
    </div>
</template>

<script>
import CompModal from "../components/CompModal.vue"

import ElemTextarea from "../../customform/components/ElemTextarea.vue"
import ElemUpload from "../../customform/components/ElemUpload.vue"
import ElemSelect from "@/views/shop/components/elem-select.vue"

export default {
    components: {
        CompModal,

        ElemTextarea,
        ElemUpload,
        ElemSelect,
    },

    data() {
        return {
            isDisplayTimeModal: false,
            // 发送时间
            sendRegularly: null,
            // 表单数据
            formdata: {},
        }
    },

    props: {
        communityCode: String,
        type: {
            type: String,
            default: "push", // 推送消息：push  发短信：sms
        },
    },

    mounted() {},

    methods: {
        display(appId) {
            this.appId = appId
            this.$refs.comp_modal.display()
        },

        /**
         * 显示定时发送页面
         */
        onDisplaySetSendRegularly() {
            this.isDisplayTimeModal = true
        },

        /**
         * 监听关闭弹窗事件
         */
        onCloseModal() {
            this.sendRegularly = null

            for (let i = 0, o = this.formdata, keys = Object.keys(o); i < keys.length; i++) {
                this.$set(o, keys[i], null)
            }

            this.$forceUpdate()
        },

        getFormData() {
            return new Promise(resolve => {
                const fd = this.formdata

                if (!fd.members || fd.members.length <= 0) {
                    return this.$Message.warning("请选择接收人")
                }

                if (!fd.text) {
                    return this.$Message.warning("请输入发送内容")
                }

                Promise.all([this.$refs.upload_image.getFiles(), this.$refs.upload_file.getFiles()]).then(res => {
                    if (res[0] && res[0].length > 0) {
                        fd.images = res[0]
                    }

                    if (res[1] && res[1].length > 0) {
                        fd.files = res[1]
                    }

                    resolve(fd)
                })
            })
        },

        /**
         * 立即发送
         */
        async onSendImmediately() {
            const fd = await this.getFormData()

            this.$post(
                "/syaa/api/syuser/pc/userBaseIndex/sendStationMessage",
                {
                    custGlobalIdList: fd.members,
                    communityCode: this.communityCode,
                    appId: this.appId,
                    orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
                    content: {
                        text: fd.text,
                        images: fd.images,
                        files: fd.files,
                    },
                },
                { "Content-Type": "application/json" }
            ).then(res => {
                if (res.code == 200) {
                    this.$Message.success("发送成功")
                    // 关闭弹窗
                    this.$refs.comp_modal.close()
                } else this.$Message.error(res.desc)
            })
        },

        /**
         * 提交定时发送配置
         */
        onSubmitSendRegularly() {
            let date = this.sendRegularly
            if (!date) {
                return this.$Message.error("时间不能为空")
            }
            // 选择时间是否小于当前时间
            if (date.getTime() < new Date().getTime()) {
                return this.$Message.error("发送时间不能小于当前时间")
            }
            // TODO 提交数据

            // 关闭弹窗
            this.$refs.comp_modal.close()
        },

        onChangeUpload(evt) {
            this.formdata[evt.name] = evt.value
        },
    },
}
</script>

<style lang="less">
.residentdatabase-send-message {
    .form-box {
        height: 100%;
        padding: 0 20px 20px 20px;
        overflow-y: auto;

        .title {
            margin-top: 5px;
            font-size: 16px;
            color: #888;
            line-height: 40px;
        }

        > .item-box {
            margin: 10px 0;
            max-width: 450px;

            .input-box {
                position: relative;
                width: 100%;

                input {
                    height: 100%;
                }

                .input {
                    position: relative;
                    height: 45px;
                    width: 100%;
                    padding: 0 50px 0 12px;
                    z-index: 10;
                    box-sizing: border-box;
                    border-radius: 6px;
                    transition: all 0.3s ease;
                    background: #fff;
                    font-size: 14px;
                    border: 1px solid #e3e3e3;
                    display: flex;
                    align-items: center;

                    &:hover,
                    &:focus {
                        border-color: #b3b3b3;
                        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                    }

                    input {
                        font-size: 14px;
                    }

                    .placeholder {
                        color: #888;
                    }
                }

                .icon-box {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    width: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 15;

                    .icon {
                        font-size: 20px;
                    }
                }
            }
        }
    }
}

.send-regularly-form-item {
    margin: 20px 20px;
    display: flex;
    align-items: center;

    .title {
        margin-right: 10px;
    }
}
</style>
