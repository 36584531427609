<template>
    <div>
        <CompModal ref="details_model" title="查看居民基础数据库" width="100%" @on-close="onCancelEdit">
            <div style="display:flex;height: 100%;">
                <div style="width:190px">
                    <Menu theme="light" v-if="menuAct" :active-name="menuAct" @on-select="onSelectMenu">
                        <MenuItem v-for="(item, idx) in form_structure" :name="item.formCode" :key="idx">
                            {{ item.formName }}
                        </MenuItem>
                    </Menu>
                </div>
                <div class="formbox">
                    <!-- 基本信息 -->
                    <div class="fnbtn" v-if="modifiable">
                        <Button type="primary" @click="isEdit = true" v-show="!isEdit">修改</Button>
                        <Button type="primary" style="margin-right: 10px" @click="onSubmitForm" v-show="isEdit">保存</Button>
                        <Button type="info" @click="onCancelEdit" v-show="isEdit">取消修改</Button>
                    </div>

                    <div class="boxItem" v-for="(item, idx) in form_structure" v-show="menuAct === item.formCode" :key="idx">
                        <CompForm full :ref="'comp_form_' + item.formCode" :disabled="!isEdit" :forms="item.formOperates" :value="item.formValue" :isDisplaySubmit="false" :data-watch="getWatchForm(item.formOperates)" :param="param" :config="formConfig"></CompForm>
                    </div>
                </div>
            </div>
        </CompModal>
        <Modal v-model="isDisplayIdNumOccupy" title="身份证号码已存在" ok-text="修改" @on-ok="onOpenEditIdNumOccupy">
            <p style="padding: 16px">当前身份证已被使用，请确认信息是否准确，如无误，请先修改占用身份证的用户信息。</p>
        </Modal>
        <!-- 身份证号重复时，使用修改 -->
        <DetailsFrom v-if="isLoadEditForm" ref="details_from" :code="code" @on-refresh="onRefresh"></DetailsFrom>
    </div>
</template>

<script>
import CompForm from "../../customform/components/CompForm.vue"
import CompModal from "../components/CompModal.vue"

export default {
    name: "DetailsFrom",

    components: {
        CompForm,
        CompModal,
    },
    props: {
        // 是否可修改
        modifiable: {
            type: Boolean,
            default: true,
        },
        code: String,
    },
    data() {
        return {
            menuAct: null,
            isEdit: false,
            form_structure: null,
            param: {},
            formConfig: {
                switch: {
                    open: "1",
                    close: "2",
                },
            },
            isDisplayIdNumOccupy: false,
            isLoadEditForm: false,
        }
    },

    watch: {
        menuAct() {
            this.onCancelEdit()
        },

        code: {
            handler(v) {
                this.param = { code: v }
            },
            immediate: true,
        },
    },

    methods: {
        reset() {
            this.menuAct = null
            this.isEdit = false
        },

        display(ubaseId) {
            this.id = ubaseId

            this.$Message.loading({
                content: "加载中...",
                duration: 0,
            })

            this.$get("/syaa/api/syuser/pc/uaUserInfo/selectUserByUbaseId", {
                ubaseId: ubaseId,
            })
                .then(res => {
                    if (res.code == 200) {
                        const data = res.data

                        const form_structure = (this.form_structure = this.processStructure(data.formProperties))
                        this.original = JSON.parse(JSON.stringify(form_structure))
                        this.menuAct = form_structure[0].formCode

                        // 显示详情页面
                        this.$refs.details_model.display()
                    } else {
                        this.$Message.error({
                            content: res.desc,
                            background: true,
                        })
                    }
                })
                .catch(() => {
                    this.$Message.error({
                        content: "数据获取失败",
                        background: true,
                    })
                })
                .finally(() => {
                    this.$Message.destroy()
                })
        },

        /**
         * 根据身份证获取数据
         */
        displayByIdNum(idNum) {
            this.$get("/syaa/api/syuser/pc/uaUserInfo/selectUserByIdNum", {
                idNum: idNum,
                communityCode: this.code,
            }).then(res => {
                if (res.code == 200) {
                    const data = res.data

                    const form_structure = (this.form_structure = this.processStructure(data.formProperties))
                    this.original = JSON.parse(JSON.stringify(form_structure))
                    this.menuAct = form_structure[0].formCode

                    this.id = data.ubaseId

                    // 显示详情页面
                    this.$refs.details_model.display()
                }
            })
        },

        processStructure(fps) {
            for (let i = 0; i < fps.length; i++) {
                const fp = fps[i]
                const value = {}

                for (let fi = 0, fos = fp.formOperates; fi < fos.length; fi++) {
                    const fo = fos[fi]

                    // 忽略详细地址组件，合并在 ElemAddr 组件
                    if (fo.fieldType === "hidden") {
                        fos.splice(fi--, 1)
                        // 赋值
                        value[fo.fieldId] = fo.fieldValue
                        continue
                    }

                    const type = this.getFormType(fo)

                    if (fo.options) {
                        if (type === "switch") {
                            // 处理默认值
                            fo.value = fo.options.find(c => c.checked)?.value
                        } else
                            fo.options.forEach(o => {
                                o.name = o.label
                            })
                    }

                    fos[fi] = {
                        ...fo,
                        type: type,
                        name: fo.fieldId,
                        title: fo.fieldName,
                        max: fo.limitMaxLen,
                        verify: fo.validate?.inputValueType,
                        multiple: fo.validate?.arrayType,
                    }

                    // 处理 checkbox 类型规则，使用分割线分隔
                    if (fo.fieldType === "checkbox" && fo.fieldValue) {
                        if (typeof fo.fieldValue === "string" && fo.fieldValue?.indexOf("|")) {
                            fo.fieldValue = fo.fieldValue.split("|")
                        }
                    }

                    value[fo.fieldId] = fo.fieldValue
                }

                fp.formValue = value
            }

            return fps
        },

        getFormType(v) {
            if (v.validate?.inputValueType === "mobile") {
                return "mobile"
            }

            return { text: "input" }[v.fieldType] || v.fieldType
        },

        onSelectMenu(val) {
            this.menuAct = val
        },

        /** 取消修改 */
        onCancelEdit() {
            if (!this.isEdit) return

            this.isEdit = false

            this.$nextTick(() => {
                // 恢复表单值
                this.form_structure = JSON.parse(JSON.stringify(this.original))
            })
        },

        getWatchForm(item) {
            return {
                // 监听表单值
                idNumType: value => {
                    for (let i = 0, fs = item; i < fs.length; i++) {
                        let f = fs[i]
                        if (f.name === "idNum") {
                            f.verify = value == 1 ? "idNum" : null
                            break
                        }
                    }
                },
            }
        },

        async onSubmitForm() {
            var formdata

            var comp = this.$refs["comp_form_" + this.menuAct]

            if (comp instanceof Array) {
                comp = comp[0]
            }

            var status = await comp.getFormData()

            if (status.code == 200) {
                formdata = status.data
            } else {
                return this.$Message.error(status.msg)
            }

            this.$post(`/syaa/api/syuser/pc/uaUserInfo/updateUserById?ubaseId=${this.id}`, { data: formdata }, { "Content-Type": "application/json" }).then(res => {
                if (res.code == 200) {
                    this.$Message.success("保存成功")
                    // 关闭编辑模式
                    this.isEdit = false
                    // 保存修改后的数据
                    for (let i = 0, forms = this.original; i < forms.length; i++) {
                        const v = forms[i]

                        if (v.formCode === this.menuAct) {
                            v.formValue = JSON.parse(JSON.stringify(formdata))
                        }
                    }
                    this.onRefresh()
                } else if (res.code === "IDNUM_EXIST") {
                    this.idNum = formdata.idNum
                    this.isDisplayIdNumOccupy = true
                } else
                    this.$Message.error({
                        content: res.desc,
                        background: true,
                    })
            })
        },

        onOpenEditIdNumOccupy() {
            this.isLoadEditForm = true
            this.$nextTick(() => {
                this.$refs.details_from.displayByIdNum(this.idNum)
            })
        },

        onRefresh() {
            this.$emit("on-refresh")
        },
    },
}
</script>

<style scoped lang="less">
/deep/.ivu-menu-light {
    width: 190px !important;
    height: 100%;
}
/deep/.validate {
    &::before {
        content: "*";
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
        font-family: SimSun;
        font-size: 14px;
        color: #ed4014;
    }
}
.fnbtn {
    padding: 8px 0px 8px 20px;
    background: rgb(244 244 244);
    flex-shrink: 0;
}
.formbox {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .boxItem {
        overflow-y: auto;
        flex-grow: 1;
        padding-left: 10px;
    }
}
</style>
